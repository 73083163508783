/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../../core/Error.js";
import { clone as t } from "../../core/lang.js";
import { getDeepValue as n } from "../../core/object.js";
import { numberToJSON as r } from "../../core/accessorSupport/extensions/serializableProperty/writer.js";
import { isFeatureCollectionLayer as i } from "../../layers/support/layerUtils.js";
const o = new Set(["bing-maps", "imagery", "imagery-tile", "map-image", "open-street-map", "tile", "unknown", "unsupported", "vector-tile", "web-tile", "wms", "wmts"]),
  l = new Set(["catalog", "csv", "feature", "geo-rss", "geojson", "group", "imagery", "imagery-tile", "kml", "knowledge-graph", "map-image", "map-notes", "media", "ogc-feature", "oriented-imagery", "route", "stream", "subtype-group", "tile", "unknown", "unsupported", "vector-tile", "web-tile", "wfs", "wms", "wmts"]);
function a(e) {
  o.delete(e), l.delete(e);
}
function s(e) {
  o.add(e), l.add(e);
}
function d(e) {
  return "basemap" === e.layerContainerType ? o : "operational-layers" === e.layerContainerType ? l : null;
}
function p(e, t) {
  if (t.restrictedWebMapWriting) {
    const n = d(t);
    return null == n || n.has(e.type) && !i(e);
  }
  return !0;
}
function u(e, t) {
  if (t) if (i(e)) {
    const r = n("featureCollection.layers", t),
      i = r?.[0]?.layerDefinition;
    i && c(e, i);
  } else "group" !== e.type && c(e, t);
}
function c(e, t) {
  "maxScale" in e && (t.maxScale = r(e.maxScale) ?? void 0), "minScale" in e && (t.minScale = r(e.minScale) ?? void 0);
}
function m(e, t) {
  if (u(e, t), t && (t.id = e.id, "blendMode" in e && (t.blendMode = e.blendMode, "normal" === t.blendMode && delete t.blendMode), t.opacity = r(e.opacity) ?? void 0, t.title = e.title || "Layer", t.visibility = e.visible, "legendEnabled" in e && "wmts" !== e.type)) if (i(e)) {
    const n = t.featureCollection;
    n && (n.showLegend = e.legendEnabled);
  } else t.showLegend = e.legendEnabled;
}
function f(n, r, o) {
  if (!n.persistenceEnabled) return null;
  if (!("write" in n) || !n.write) return o?.messages && o.messages.push(new e("layer:unsupported", `Layers (${n.title}, ${n.id}) of type '${n.declaredClass}' cannot be persisted`, {
    layer: n
  })), null;
  if (i(n) && !n.isTable) r = n.resourceInfo;else if (p(n, o)) {
    const e = {};
    return n.write(e, o) ? e : null;
  }
  return null != r && m(n, r = t(r)), r;
}
export { s as disableRestrictedWriting, a as enableRestrictedWriting, f as getLayerJSON };